<template>
  <div class="grid grid-cols-1 lg:grid-cols-6 gap-2 place-content-start">
    <div class="flex flex-col">
      <span class="font-bold">Tipo de egreso:</span>
      <Dropdown v-model="expenseTypeSelected" :options="expensesTypes" optionLabel="name" optionValue="id"
                placeholder="Seleccione un tipo" @change="onChangeExpensesTypes"/>
    </div>
    <!-- <div class="flex flex-col">
      <span class="font-bold">N° Documento:</span>
      <InputText type="text" placeholder="Digite el N° Documento" v-model="numberDoc" @keyup="onChangeNumberDoc"/>
    </div> -->
    <div class="flex items-end justify-center">
      <Button label="Limpiar filtro"
              class="mr-2 p-button-info"
              @click="clearFilter"
      />
    </div>
    <div class="flex flex-col justify-end">
      <span class="font-bold">Total Gastos:</span>
      <span>{{ $h.formatCurrency(storeDetailExp) }}</span>
    </div>
    <div class="flex flex-col justify-end">
      <span class="font-bold">Total Registros:</span>
      <span>{{ getExpenses.count }}</span>
    </div>
    <div class="flex items-end justify-center">
      <Button label="Agregar Egreso"
              v-if="$can('new-gas-and-oil.caja-menor.ver-caja-menor.agregar')"
              icon="pi pi-th-large"
              class="mr-2 p-button-info"
              @click="dialogAddExpense = !dialogAddExpense"
      />
    </div>
  </div>

  <Dialog v-model:visible="dialogAddExpense" :style="{width: '450px'}" header="Agregar Egreso" :modal="true"
          class="p-fluid">
    <div class="field">
      <label for="concept">Tipo de egreso</label>
      <Dropdown v-model="expense.expenseType" :options="expensesTypes" optionLabel="name" optionValue="id"
                placeholder="Seleccione un tipo"/>
    </div>
    <div class="field">
      <label>Regimen</label>
      <Dropdown v-model="expense.regimen" :options="getRegimen" optionLabel="name" optionValue="id"
                placeholder="Seleccione un tipo"/>
    </div>
    <template v-if="expense.regimen === 2">
      <div class="field mt-2">
        <label for="nit">NIT</label>
        <InputText id="nit"
                   v-model.trim="expense.nit"
                   required="true"
                   class="p-inputtext-sm p-mt-1"
        />
      </div>
      <div class="field mt-2">
        <label for="direccion">Direccion</label>
        <InputText id="direccion"
                   v-model.trim="expense.direccion"
                   required="true"
                   class="p-inputtext-sm p-mt-1"
        />
      </div>
      <div class="field mt-2">
        <label for="telefono">Telefono</label>
        <InputNumber
          id="telefono"
          v-model="expense.telefono"
          :useGrouping="false"
        />
      </div>
    </template>
    <div class="field mt-2">
      <label for="client">Beneficiario</label>
      <InputText id="client"
                 v-model.trim="expense.client"
                 required="true"
                 class="p-inputtext-sm p-mt-1"
      />
    </div>
    <div class="field mt-2">
      <label for="concept">Concepto</label>
      <InputText id="concept"
                 v-model.trim="expense.concept"
                 required="true"
                 class="p-inputtext-sm p-mt-1"
      />
    </div>
    <div class="field mt-2">
      <label for="amount">Valor</label>
      <InputNumber id="amount" v-model="expense.amount" mode="currency" currency="COP" locale="es-CO" :maxFractionDigits="0"
      />
    </div>
    <div class="field mt-2">
      <label for="amount">Documento</label>
      <input type="file" id="myfile" accept=".pdf" @change="onUpload">
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="dialogAddExpense = !dialogAddExpense"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class=""
        @click="saveExpense"
      />
    </template>
  </Dialog>
  <Toast/>
</template>

<script>
import storeExpenses from '../../../../stores/storeExpenses'
import storeHeader from '../../../../stores/storeHeader'
import { computed, ref, onMounted } from 'vue'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
import { helper } from '../../../../../../../utils/helper'

export default {
  name: 'homeTabsEgresosFullHeader',
  setup () {
    const getCashBalance = computed(() => storeHeader.getters.getCashBalance)
    const toast = useToast()
    const paginator = computed(() => storeExpenses.getters.getPaginator)
    const numberDoc = ref(null)
    const expenseTypeSelected = ref(null)
    const expensesTypes = computed(() => storeExpenses.getters.getTypesExpenses)
    const storeDetailExp = computed(() => storeHeader.getters.getDetailExp)
    const getExpenses = computed(() => storeExpenses.getters.getExpenses)
    const dialogAddExpense = ref(false)
    const expense = ref({
      concept: '',
      regimen: null,
      expenseType: null,
      amount: 0,
      nit: null,
      direccion: null,
      telefono: null,
      client: null,
      document: null
    })
    const getRegimen = computed(() => storeExpenses.getters.getRegimen)

    const clearFilter = () => {
      if (numberDoc.value !== null || expensesTypes.value !== null) {
        numberDoc.value = null
        expenseTypeSelected.value = null
        storeExpenses.dispatch('searchIncomes', { limit: 8, offset: 0 })
        storeExpenses.dispatch('gettingPaginator', { rows: 8, first: 0 })
      }
    }

    const onChangeExpensesTypes = () => {
      storeExpenses.dispatch('searchExpenses', {
        limit: 8,
        offset: 0,
        expenseType: expenseTypeSelected.value
        // numberDoc: numberDoc.value
      })
      storeExpenses.dispatch('gettingPaginator', { rows: 8, first: 0 })
    }

    const onChangeNumberDoc = () => {
      storeExpenses.dispatch('searchExpenses', {
        limit: 8,
        offset: 0,
        expenseType: expenseTypeSelected.value,
        numberDoc: numberDoc.value
      })
      storeExpenses.dispatch('gettingPaginator', { rows: 8, first: 0 })
    }

    const saveExpense = () => {
      if (expense.value.expenseType !== null &&
        expense.value.concept !== '' &&
        expense.value.amount > 0 &&
        expense.value.regimen !== null &&
        expense.value.client !== ''
      ) {
        if (expense.value.amount <= getCashBalance.value) {
          if (expense.value.regimen === 2) {
            if (!(expense.value.nit !== '' &&
              expense.value.direccion !== '' &&
              expense.value.telefono !== null)
            ) {
              Swal.fire(
                'Advertencia',
                'Todos los campos son obligatorios',
                'warning'
              )
              return
            }
          }
          var formData = new FormData()
          formData.append('file', expense.value.document)
          formData.append('concept', expense.value.concept)
          formData.append('expenseType', expense.value.expenseType)
          formData.append('regimen', expense.value.regimen)
          formData.append('amount', expense.value.amount)
          formData.append('client', expense.value.client)
          if (expense.value.nit) formData.append('nit', expense.value.nit)
          if (expense.value.direccion) formData.append('direccion', expense.value.direccion)
          if (expense.value.telefono) formData.append('telefono', expense.value.telefono)
          storeExpenses.dispatch('saveExpenseFull', formData).then(() => {
            storeExpenses.dispatch('searchExpenses', {
              limit: paginator.value.rows,
              offset: paginator.value.first,
              expenseType: expenseTypeSelected.value,
              numberDoc: numberDoc.value
            })
            dialogAddExpense.value = false
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Se agrego el egreso correctamente', life: 3000 })
            storeHeader.dispatch('gettingCashBalance')
            storeHeader.dispatch('gettingTotalExpenses')
            storeHeader.dispatch('gettingExpensesDetail')
            expense.value.expenseType = null
            expense.value.concept = ''
            expense.value.amount = 0
            expense.value.document = null
            expense.value.regimen = null
            expense.value.client = null
            expense.value.nit = null
            expense.value.direccion = null
            expense.value.telefono = null
            document.getElementById('myfile').value = ''
          })
        } else {
          Swal.fire(
            'Advertencia',
            `El valor supera el saldo restante del ingreso. (${helper.formatCurrency(getCashBalance.value)})`,
            'warning'
          )
        }
      } else {
        Swal.fire(
          'Advertencia',
          'Todos los campos son obligatorios',
          'warning'
        )
      }
    }

    const onUpload = (event) => {
      expense.value.document = event.target.files[0]
    }

    onMounted(() => {
      storeExpenses.dispatch('searchTypesExpenses')
      storeExpenses.dispatch('searchExpenses', { limit: 8, offset: 0 })
      storeHeader.dispatch('gettingExpensesDetail')
    })

    return {
      numberDoc,
      expensesTypes,
      expenseTypeSelected,
      storeDetailExp,
      getExpenses,
      dialogAddExpense,
      expense,
      getRegimen,
      clearFilter,
      onChangeExpensesTypes,
      onChangeNumberDoc,
      saveExpense,
      onUpload
    }
  }
}
</script>

<style>
.swal2-container {
  z-index: 9999;
}
</style>
