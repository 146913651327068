<template>
  <DataTable :value='storeExpensesList.rows' responsiveLayout='scroll' class="p-datatable-sm mt-4">
    <template #empty>
      No hay datos para mostrar
    </template>
    <Column field='createdAt' header='Fecha'>
      <template #body="{data}">
        {{ $h.formatDate(data.createdAt) }}
      </template>
    </Column>
    <Column header='Tipo de Egreso'>
      <template #body="{data}">
        {{ data.expenseTypeName.name }}
      </template>
    </Column>
    <Column field='nit' header='NIT'>
      <template #body="{data}">
        {{ data.nit ? data.nit : 'No registra' }}
      </template>
    </Column>
    <Column field='client' header='Beneficiario'></Column>
    <Column field='concept' header='Concepto' style="width: 40%"></Column>
    <Column field='amount' header='Valor'>
      <template #body="{data}">
        {{ $h.formatCurrency(parseFloat(data.amount), 2) }}
      </template>
    </Column>
    <Column field='createdBy' header='Responsable'>
      <template #body="{data}">
        {{ data.people.full_name }}
      </template>
    </Column>
    <Column field='acciones' header='Acciones'>
      <template #body="{data}">
        <template v-if="$can('new-gas-and-oil.caja-menor.ver-caja-menor.access')">
          <Button icon="pi pi-trash"
                  class="p-button-rounded mr-2 p-button-info"
                  v-tooltip.top="{value: 'Eliminar'}"
                  @click="deleteExpense(data.id)"
          />
        </template>
        <Button icon="pi pi-eye"
                class="p-button-rounded mr-2 p-button-help"
                v-tooltip.top="{value: 'Ver documento'}"
                @click="verPdf(data.path)"
        />
      </template>
    </Column>
  </DataTable>
  <Paginator v-model:first="paginator.first" :rows="paginator.rows" :totalRecords="storeExpensesList.count" @page="onPage($event)"></Paginator>
</template>

<script>
import { computed } from 'vue'
import storeExpenses from '../../../../stores/storeExpenses'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
import { useRoute } from 'vue-router'
import storeHeader from '@/apps/newGasAndOil/newGasPettyCash/stores/storeHeader'

export default {
  name: 'homeTabsEgresosFullTable',
  setup () {
    const storeExpensesList = computed(() => storeExpenses.getters.getExpenses)
    const paginator = computed(() => storeExpenses.getters.getPaginator)
    const toast = useToast()
    const route = useRoute()

    const deleteExpense = (id) => {
      Swal.fire({
        title: 'Esta seguro/a?',
        text: 'Si se elimina el egreso no se podrá recuperar!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminalo!'
      }).then((result) => {
        if (result.isConfirmed) {
          storeExpenses.dispatch('deleteExpense', id).then(() => {
            storeExpenses.dispatch('searchExpenses', { limit: paginator.value.rows, offset: paginator.value.first, idPettyCashIncome: route.params.id })
            storeHeader.dispatch('gettingCashBalance')
            storeHeader.dispatch('gettingTotalExpenses')
            storeHeader.dispatch('gettingExpensesDetail', { idPettyCashIncome: route.params.id })
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Se elimino el egreso correctamente', life: 3000 })
          })
        } else {
          toast.add({ severity: 'info', summary: 'Informacion', detail: 'Se cancelo correctamente', life: 3000 })
        }
      })
    }

    const onPage = (event) => {
      storeExpenses.dispatch('gettingPaginator', paginator.value)
      storeExpenses.dispatch('searchExpenses', { limit: paginator.value.rows, offset: event.first, idPettyCashIncome: route.params.id })
    }

    const verPdf = (ruta) => {
      if (ruta) {
        storeExpenses.dispatch('getPdf', { ruta }).then((data) => {
          window.open(data, '_blank')
        })
      } else {
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'No tiene documento cargado', life: 3000 })
      }
    }

    return {
      storeExpensesList,
      verPdf,
      deleteExpense,
      onPage,
      paginator
    }
  }
}
</script>

<style scoped>

</style>
